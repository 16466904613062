<template>
  <v-container
    id="FQA"
    tag="section"
    fluid
    fill-height
  >
    <!-- color="rgba(236, 6, 141, 1)" -->
    <!-- <base-card
      class="pt-0"
    >
      <v-container> -->
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pb-10"
          >
            <base-subheading class="mb-3" dark>
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.FAQ') }}</strong></span>
              <v-divider></v-divider>
            </base-subheading>

            <v-row row wrap>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="display-2">Q: การขอสินเชื่อหรือใช้บริการ ติดต่อได้ช่องทางใดบ้าง</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="display-2 pink--text">A: 1. ติดต่อโดยตรง ได้ที่ ที่ทำการบริษัท มีที่ มีเงิน จำกัด</div>
                    <div class="indent50 display-2 pink--text">63/2 อาคาร บริษัท ทิพยประกันภัย จำกัด (มหาชน) ชั้น 2 ถ.พระราม 9</div>
                    <div class="indent50 display-2 pink--text">แขวงห้วยขวาง เขตห้วยขวาง กทม. 10310 โทรศัพท์ 02-025-6999</div>
                    <div class="indent50 display-2 pink--text">เวลาทำการ 08:30 – 17:00 น.</div>
                    <!-- <div class="indent50 display-2 pink--text">Location (Link: <a href="https://goo.gl/maps/dc12SLiy48CYvuDu9" target="_blank">google maps</a>)</div> -->
                    <!-- <div class="indent50 display-2 pink--text">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d968.8228538684155!2d100.57022562918455!3d13.76129179939634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fc0a7c972b3%3A0x838fd31fd3874ecb!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4oeC4teC4l-C4teC5iCDguKHguLXguYDguIfguLTguJkg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1669257076837!5m2!1sth!2sth" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> -->
                    <div class="indent15 display-2 pink--text">
                      2. ธนาคารออมสิน ทุกสาขา เวลาทำการขึ้นอยู่กับแต่ละสาขา
                    </div>
                    <div class="indent15 display-2 pink--text">
                      3. ช่องทางเว็บไซต์ของบริษัท <a href="https://www.meetee.co.th" target="_blank">www.meetee.co.th</a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <!-- <span class="display-2">{{ $t('MeeTeeMeeNgern1.Question2') }}</span> -->
                    <span class="display-2">Q: บริษัท มีที่ มีเงิน จำกัด ให้บริการอะไรบ้าง</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-2 pink--text">A: ให้บริการสินเชื่อที่ดิน (จำนอง) สำหรับผู้ประกอบการ โดยรับหลักประกันเป็นที่ดินเปล่า ที่ดินพร้อมสิ่งปลูกสร้าง และ อาคารชุด และจะเริ่มให้บริการขายฝาก ภายในปี 2566 เป็นลำดับต่อไป</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: หลักประกันที่สามารถนำมาขอวงเงินสินเชื่อได้ มีอะไรบ้าง</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="display-2 pink--text">A: ประเภทที่ 1 โฉนดที่ดิน (นส.4) ให้บริการ ที่ตั้งหลักประกัน 77 จังหวัด ทั่วประเทศ</div>
                    <div class="indent15 display-2 pink--text">
                      ประเภทที่ 2 ห้องชุด (อช.2) รับเฉพาะพื้นที่ในเขต กรุงเทพมหานคร
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: การขอสินเชื่อต้องมีบุคคลค้ำประกันหรือไม่</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-2 pink--text">A: ไม่จำเป็นต้องมีบุคคลค้ำประกัน</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: การขอสินเชื่อต้องทำประกันคุ้มครองวงเงินสินเชื่อ (MRTA) หรือไม่</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-2 pink--text">A: บริษัทไม่บังคับการทำประกันคุ้มครองวงเงินสินเชื่อ (MRTA)</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: วัตถุประสงค์ในการขอสินเชื่อที่ดิน (จำนอง)</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="display-2 pink--text">A: เพื่อเสริมสภาพคล่องของกิจการ เพื่อการลงทุน เพื่อไถ่ถอนจำนอง(Refinance)</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: ค่าใช้จ่ายในการดำเนินการมีอะไรบ้าง</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="display-2 pink--text">
                      A: 1. ค่าธรรมเนียมบริการสินเชื่อ (Front-End Fee)
                    </div>
                    <div class="indent15 display-2 pink--text">
                      2. ค่าสำรวจหลักประกัน กรณีใช้ราคาประเมินราชการ / ค่าประเมินราคาหลักประกัน กรณีใช้ราคาตลาด (จ่ายตามจริง)
                    </div>
                    <div class="indent15 display-2 pink--text">
                      3. ค่าประกันอัคคีภัย  (ถ้ามี)
                    </div>
                    <div class="indent15 display-2 pink--text">
                      4. ค่าอากรแสตมป์
                    </div>
                    <div class="indent15 display-2 pink--text">
                      5. ค่าจดทะเบียนนิติกรรม (จำนอง)
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">Q: ช่องทางการผ่อนชำระค่างวดมีช่องทางใดบ้าง</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="display-2 pink--text">A: 1. ธนาคารออมสินทุกสาขา (ธนาคารยกเว้นค่าธรรมเนียมในการให้บริการ) </div>
                    <div class="indent15 display-2 pink--text">
                      2. ผ่านแอพพลิเคชั่น MyMo ของธนาคารออมสิน
                    </div>
                    <div class="indent15 display-2 pink--text">3. ที่ทำการบริษัท มีที่ มีเงิน จำกัด  </div>
                    <div class="indent50 display-2 pink--text">63/2 อาคาร บริษัท ทิพยประกันภัย จำกัด (มหาชน) ชั้น 2 ถ.พระราม 9</div>
                    <div class="indent50 display-2 pink--text">แขวงห้วยขวาง เขตห้วยขวาง กทม. 10310 โทรศัพท์ 02-025-6999</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
        </v-row>
        <v-sheet>
          <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26356315.701909266!2d-113.65984645545673!3d36.25591957613731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited+States!5e0!3m2!1sen!2sus!4v1566158729223!5m2!1sen!2sus"
            width="100%"
            height="450"
            frameborder="0"
            style="border:0"
            allowfullscreen
          /> -->
        </v-sheet>
        <!-- <base-material-card
          color="success"
          title="Satellite Map"
          class="px-5 py-3"
        >
          <v-card-text class="px-0 pb-0">
            <v-sheet>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26356315.701909266!2d-113.65984645545673!3d36.25591957613731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited+States!5e0!3m2!1sen!2sus!4v1566158729223!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameborder="0"
                style="border:0"
                allowfullscreen
              />
            </v-sheet>
          </v-card-text>
        </base-material-card> -->
      </v-container>
    <v-dialog
      v-model="dialog.dialogPolicy"
      width="800px"
    >
      <v-card>
        <!-- <v-card-title>
          <span class="text-h5">Use Google's location service?</span>
        </v-card-title> -->
        <v-card-text>
          <cookiepolicy></cookiepolicy>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            dark
            text
            @click="dialog.dialogPolicy = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        appName: process.env.VUE_APP_DB_APP_NAME,
        dialog: {
          dialogPolicy: false,
        },
        NameSurName: '',
        PolicyFlag: '',
        subscribeText: '',
        email: '',
        tel: '',
        ipAddress: '',
      }
    },
    components: {
      cookiepolicy: () => import('@/components/cookie-policy'),
    },
    methods: {
      initialize () {
        this.NameSurName = ''
        this.PolicyFlag = ''
        this.subscribeText = ''
        this.email = ''
        this.tel = ''
        this.ipAddress = ''
      },
      CookieLaw () {
        this.dialog.dialogPolicy = true
      },
      test () {
        console.log('test', '')
      },
      async addSubscribe () {
        if (!this.NameSurName) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageName')})
          return
        }
        /* if (!this.email) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageEmail')})
          return
        } */
        if (!this.tel) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageTel')})
          return
        }
        if (!this.subscribeText) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messageSubscribeMessage')})
          return
        }
        if (!this.PolicyFlag) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messagePolicy')})
          return
        }
        await this.axios.post(process.env.VUE_APP_API + '/subscribe/addSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
          name: this.NameSurName,
          policy_flag: this.PolicyFlag,
          subscribe_text: this.subscribeText,
          email: this.email,
          tel: this.tel,
          ip_address: this.ipAddress,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.initialize()
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
<style scoped>
  .bg-editor {
    /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(255, 255, 255) !important;
  }
  .input__label {
    color: black;
  }
  div.a {
    text-indent: 15px;
  }
  div.indent15 {
    text-indent: 25px;
  }
  div.indent50 {
    text-indent: 48px;
  }
  div.indent35 {
    text-indent: 25px;
  }

  p.a {
    margin-left: 2em;
    text-indent: -1.5em;
  }

  div.c {
    text-indent: 30%;
  }
</style>